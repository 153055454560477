<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :src="barImage"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            class="text-center"
            v-text="profile.title"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>

    <template v-slot:append>
      <base-item
        :item="{
          title: 'Settings',
          icon: 'mdi-cog',
          to: '/dashboard/settings/',
        }"
      />
    </template>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'
  import Invoice from "@/models/Invoice";

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        items: [
          {
            icon: 'mdi-view-dashboard',
            title: 'Dashboard',
            to: '/dashboard/',
            exact: true
          },
          {
            icon: 'mdi-hexagon-slice-2',
            title: 'Create shipment',
            to: '/dashboard/quote',
          },
          {
            icon: 'mdi-package-variant-closed',
            title: 'Shipments',
            to: '/dashboard/shipments',
          },
          {
            icon: 'mdi-webpack',
            title: 'Shipping packages',
            to: '/dashboard/shipping_packages',
          },
          {
            icon: 'mdi-file-document-multiple',
            title: 'Invoices',
            to: '/dashboard/invoices',
            badge: 0,
          },
        ],
      }
    },

    computed: {
      ...mapState('dashboard', ['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('dashboard/SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        return {
          avatar: true,
          title: 'BOXHUB',
        }
      },
    },

    methods: {
      mapItem (item) {
        // TODO: it seems it was required only for i18n, delete it if true.
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: item.title,
          badge: item.badge
        }
      },
    },
    async created() {
      const response = await Invoice.api().get('/api/v1/accounting/invoices/?status=approved', {save: false})
      const pendingInvoicesCount = response.response.data.count
      this.items = this.items.map(item => (item.title === 'Invoices') ? {...item, badge: pendingInvoicesCount} : {...item})
    }
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .mdi-hexagon-slice-2
      transform: rotate(-60deg)

    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
